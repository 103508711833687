#xmas {
	z-index: 4;
	width: 100%;
	height: 100%;
	position: relative;
	pointer-events: none;
}
#xmas.add{
	z-index: 9999;
	pointer-events: auto;
}
.main-slider .caption img{margin: 0 auto;}
.error-section{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	position: absolute;
}
.error-section:after{display: none;}
#main .stretch.snow:after{
	bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    content: "";
	background: rgba(0, 0, 0, 0.42);
}
#main .stretch.snow.add:after{background: rgba(0, 0, 0, 0.65);}
#footer.xmas{
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	position: absolute;
}
@media only screen and (max-width: 595px) {
	#footer.xmas{
		position: static;
		background: #2a2a2a;
	}
	.main-slider .caption img,
	.error-section img{max-width: 250px;}
}